/* =================================
        iTechNotion Pvt. Ltd.
================================= */

/* =============== 
   Common CSS 
=============== */
a:hover{
    color: #3192D4;
}
.bg{
    background-color: #181818 !important;
}
.bg-dropdown{
    background-color: #313436;
    border: solid 1px rgba(151,151,151,0.55);
}
.bg-edit{
    background-color: rgba(46, 49, 54, 0.20);
}
.bg-edit-profile{
    background: #4a4a4a;
}
.bg-lights{
    background: #373737;
}
.bg-setting{
    background-color: #2E3136;
}
.bg-setting-box{
    background-color: #D8D8D8;
}
.bg-login{
    background: #181818;
}
.fw-18{
    font-size: 18px;
}
.fw-22{
    font-size: 22px;
}
.label-color{
    color: rgba(255, 255, 255, 0.79);
    /* opacity: 79%; */
}
.text-label{
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.5px;
    color: #e0e0e0;
}
.text-primary{
    color: #3192D3 !important;
}
.text-dark-light{
    color: #a6aebc;
}
.text-green{
    color: #1DD1A1;
}
.text-red{
    color: #D0021B;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.border{
    border: 1px solid #404040 !important;
}
.borders-primary{
    border-color:#50E3C2 !important;
}
.borders-yellow{
    border-color:#F5A623 !important;
}
.borders-purple{
    border-color: #846BFF !important;
}
.border-0{
    border: none !important;
}
.border-bottom{
    border-bottom: solid 1px #474747 !important;
}
.border-right{
    border-right: solid 1px #474747 !important;
}

.border-left{
    border-left: solid 1px #474747 !important;
}
.m-h-200{
    min-height: 200px;
}
.m-h-150{
    min-height: 150px;
}
.form-control {
    background: #373737;
    border: 1px solid #404040;
    color: #adb5bd !important;
}
.form-control:focus {
    border-color: #3192D3;
    background: #373737;
    box-shadow: none;
}
.btn.btn-xs {
    padding: 6px 16px;
    font-size: 14px;
}
.btn.btn-footers {
    padding: 6px 20px;
    font-size: 14px;
}
.btn.btn-dark-footer {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    color: rgba(255, 255, 255, 0.61) !important;
    box-shadow: 0 3px 5px 0 transparent;
    /* opacity: 61%; */
}
.btn-dark-light {
    background-color: rgba(71,71,71,0.5) !important;
    border: 1px solid rgba(151,151,151,0.34) !important;
    color: #FFFFFF !important;
    box-shadow: 0 3px 5px 0 rgba(71,71,71,0.30);
}
  
.btn-btn-dark-light:hover, .btn-btn-dark-light:focus, .btn-btn-dark-light:active, .btn-btn-dark-light.active, .btn-btn-dark-light.focus {
    background-color: #284bc1 !important;
    border-color: #284bc1 !important;
    color: #ffffff !important;
}
.btn.btn-nav-light {
    color: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid transparent !important;
}
.btn.btn-icon-nav {
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.btn.btn-icon-toggle {
    height: 30px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.form-time{
    padding: 0.6rem 2.25rem 0.375rem 0.75rem;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==") !important;
    background-repeat: no-repeat;
    background-position: right;
}
.form-time:focus{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==") !important;
    background-repeat: no-repeat;
    background-position: right;
}


/* SingIn and SingUp css */
.text-terms{
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.5px;
    color: #a6aebc;
}
.border-top {
    border-top: 1px solid #34383F!important;
}
.card{
    background-color: #24282B;
}


/* Dashboard css */
.stream_start p{
    margin-bottom: 37px;
}
.border-green{
    border-bottom: solid 15px #50E3C2;
}
.border-yellow{
    border-bottom: solid 15px #F5A623;
}
.border-purple{
    border-bottom: solid 15px #846BFF;
}
.features.feature-primary .icon-green{
    color: #50E3C2 !important;
}
.features.feature-primary .icon-yellow{
    color: #F5A623 !important;
}
.features.feature-primary .icon-purple{
    color: #846BFF !important;
}
.dash_details h6{
    color: rgba(255, 255, 255, 0.79);
    /* opacity: 79%; */
}

.summary_heading h6{
    color: rgba(255, 255, 255, 0.80);
    /* opacity: 80%; */
    text-transform: uppercase;
}
.summary_heading p{
    color: rgba(255, 255, 255, 0.75);
    /* opacity: 75%; */
}
.full_border{
    position: relative;
}
.full_border::before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-color: #474747;
    width: 1px;
    height: calc(100vh - 180px);
}
/* modal css */
@media (min-width: 576px){
    .modal-dialog {
        max-width: 555px;
        margin: 1.75rem auto;
    }
}
.modal{
    background: rgba(0,0,0,0.43);
}
.modal-header .modal-title{
    color: rgba(255, 255, 255, 0.71);
    font-weight: initial;
    /* opacity: 71%; */
}

/* Dashboard login css */
.small_icon{
    width: 20px;
    height: 20px;
    line-height: 20px;    
    color: #fff;
    text-align: center;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.2);
}
.small_icon.green{
    background-color: #1DD1A1;
}
.small_icon.red{
    background-color: #D0021B;
}
.upcoming_description small{
    font-size: 14px;
    color: rgba(255, 255, 255, 0.53);
    /* opacity: 53%; */
    letter-spacing: normal;
}
.upcoming_details h5{
    font-size: 16px;
    margin-bottom: 0;
}
.upcoming_details p,
.upcoming_details p a{
    color: rgba(255, 255, 255, 0.70);
    /* opacity: 70%; */
}
.upcoming_details p a:hover{
    color: rgba(54, 174, 255, 1);
    /* opacity: 100%; */
}

.progress-circle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ebebeb; 
}
  
.progress-circle:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* -webkit-animation: colorload 2s;
    animation: colorload 2s;  */
}
  
.progress-circle span {
    font-size: 1rem;
    color: #CAD0D8;
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    text-align: center;
    border-radius: 50%;
    background: #181818;
    z-index: 1; 
}
  
/* .progress-circle span:after {
    content: "";
    font-weight: 600;
    color: #8b8b8b; 
} */

.progress-circle.progress-1:after {
    background-image: linear-gradient(-18deg, #3192D4 50%, transparent 50%, transparent), linear-gradient(270deg, #3192D4 50%, #ebebeb 50%, #ebebeb); 
}
.progress-circle.progress-2:after {
    background-image: linear-gradient(-18deg, #846BFF 50%, transparent 50%, transparent), linear-gradient(270deg, #846BFF 50%, #ebebeb 50%, #ebebeb); 
}
.progress-circle.progress-3:after {
    background-image: linear-gradient(-18deg, #FF9F43 50%, transparent 50%, transparent), linear-gradient(270deg, #FF9F43 50%, #ebebeb 50%, #ebebeb); 
}
.setting_card{
    background-color: #2E3136;
    border:solid 2px rgba(151,151,151,0.1);
    border-radius: 5px;
}
.setting_card.active{
    border:solid 2px #3192D3;
}
.private_card{
    background-color: rgba(216, 216, 216, 0.29);
    padding: 5px 6px;
    border-radius: 3px;
}
.private_card span{
    color: rgba(255, 255, 255, 0.47);
}
.private_card_copy span{
    width: 318px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
}
.private_card .public_copy{
    color: #ffffff;
}
.private_card .public_copy:hover{
    color: #3192D4 !important;
}
.public_copy {
    color: #fff;
    display: inline-block;
    cursor: pointer;
    transition: 0.5s;
}
.public_copy:hover{
    color: #3192D3;
}

/* upload input profile start */
.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
  
.file-input__label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    /* opacity: 65%; */
    font-size: 14px;
    padding: 10px 12px;
    background-color: #4a4a4a;
    border: 1px solid #4a4a4a;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
  
.file-input__label svg {
    height: 18px;
    margin-right: 6px;
    color: rgba(255, 255, 255, 0.74);
    /* opacity: 74%; */
}
/* upload input profile end */

/* upload edit profile start */
.file-input__input2 {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
  
.file-input__label2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.60);
    /* opacity: 60%; */
    font-size: 15px;
    padding: 6px 5px;
    background-color: transparent;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
  
.file-input__label2 svg {
    height: 18px;
    margin-right: 6px;
    color: rgba(255, 255, 255, 0.74);
    /* opacity: 74%; */
}
.edit_profile_remove a{
    font-size: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.60);
    /* opacity: 60%; */
    padding: 6px 5px;
}
/* upload edit profile end */


/* table tab css */
.tab-menu {
    display: flex;
    grid-gap: 24px;
    padding: 0 0 10px;
    border-bottom: 1px solid rgba(226, 228, 232, 20%);
    position: relative;
    flex-wrap: wrap;
}
.tab-menu li{
    list-style: none;
}
.tab-menu > li {
    position: relative;
    cursor: pointer;
    color: rgba(166, 174, 188, 40%);
    transition: all .3s ease;
    white-space: nowrap;
}
.tab-menu > li:hover {
    color: #ffffff;
}
.tab-menu > li.active {
    color: #ffffff;
}
.tab-menu .line {
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 1px;
    background: #ffffff;
    transition: all .3s ease;
}
.content {
    padding: 25px 0 0;
    display: none;
}
.content.active {
    display: block;
}

/* breadcrumb css */
.breadcrumb .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.70);
    /* opacity: 70%; */
}

.active_stream_tab{
    color: white;
    text-decoration: none;
}

.deActive_stream_tab{
    color: rgba(166, 174, 188, 40%);
    text-decoration: none;
}

.uploaded_profile{
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.60);
    /* opacity: 60%; */
    /* padding: 6px 5px; */
    cursor: pointer;
}

.uploaded-image-list{
    height: 220px;
    overflow: auto;
}

#uploaded-image-list::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  #uploaded-image-list::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  #uploaded-image-list::-webkit-scrollbar-thumb {
    background: #373737; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  #uploaded-image-list::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .rc-time-picker-input{
    background: #373737; 
    border: none;
  }

  .DayPickerInput-Overlay{
      background:#373737;
  }
  
  .table-responsive::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .table-responsive::-webkit-scrollbar-thumb {
    background: #373737; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .text-manual-white {
      color: white;
  }

  .text-muted2{
    color: rgba(255,255,255,0.71);
  }

  .form-time2{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==") !important;
    background-repeat: no-repeat;
    background-position: right;
    padding: 0px 30px 0 9px;
  }
  .form-time2:disabled{
    background-color: #141618;
  }
  .form-time2:focus{
    /* background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==") !important;
    background-repeat: no-repeat;
    background-position: right;
  }

  .bg-color-gray {
    background-color: #44484c !important;
  }

  /*======================================= 
                react side css 
  =========================================*/

  /* time piker css */
  .form-control-time{
      display: inherit;
  }
  .form-control-time input{
    display: block;
    width: 100%;
    font-weight: 400;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    min-height: calc(1.5em + 1rem + 8px);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 14px;
    line-height: 26px;
    background: #373737;
    border: 1px solid #404040;
    color: #adb5bd !important;
    outline: none;
}
.form-control-time input:focus {
    border-color: #3192D3;
    background: #373737;
    box-shadow: none;
}

.rc-time-picker-panel-inner{
    position: relative;
    font-size: 12px;
    background-color: #373737;
    box-shadow: 0 0 5px #3737374a;
    border: 1px solid #24282b;
    width: 238px;
    padding: 10px 5px;
}
.rc-time-picker-panel-input-wrap{
    border-bottom: none;
    display: none;
}
li.rc-time-picker-panel-select-option-selected{
    background: #24282b;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
}
.rc-time-picker-panel-select li{
    color: #fff;
    border-radius: 5px;
}
.rc-time-picker-panel-select li:hover {
    background: #24282b;
    border-radius: 5px;
}
.rc-time-picker-panel-select{
    width: 70px;
    border: solid 1px rgba(233, 233, 233, 0.15);
    border-top: 0;
    border: 0;
    margin-right: 5px;
}
.rc-time-picker-clear{
    top: 10px;
}

.manual-pointer{
    cursor: pointer;
    color: white;
}

.h-blue:hover{
  color: #1890ff;
}

/* modal add close icon */
.btn-close{
    filter: invert(1);
}

/* custom antd table css start */
.custom_table .ant-table {
    color: rgba(255, 255, 255, 0.63);
    background: #181818;
}
.custom_table .ant-table-thead > tr > th{
    background-color: rgba(243, 246, 248, 0.07);
    box-shadow: 0 -1px 0 0 rgba(231, 235, 238, 0.07);
    color: rgba(255, 255, 255, 0.65);
    border-bottom: none;
    padding: 14px 14px;
}
.custom_table .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: rgba(243, 246, 248, 0.07);
}
.custom_table .ant-table-tbody > tr > td{
    border-bottom: none;
    box-shadow: inset 0 -1px 0 0 rgba(255,255,255,0.09);
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
    background-color: #272728;
    border: 1px solid #272728;
    color: rgba(255, 255, 255, 0.80);
    border: solid 1px #34343478;
}
.ant-pagination-item{
    background-color: #272728;
    border: 1px solid #272728;
    color: rgba(255, 255, 255, 0.80);
}
.ant-pagination-item-active {
    font-weight: 500;
    background-color: #222229;
    color: rgba(255, 255, 255, 0.80);
    border-color: #1890ff;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.50);
}

/* All profile images responsive css */
.avatar.avatar-stream_p {
    height: 120px;
    width: 150px !important;
    object-fit: cover;
}
  


/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #373737; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } 


  /* ======================================================================================
                            WHITE LANDING PAGE CSS START
========================================================================================= */

#topnav {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: transparent;
    border: 0;
    transition: all .5s ease;
  }
  
  #topnav .logo {
    float: left;
    color: rgba(255, 255, 255, 0.78) !important;
  }
  
  #topnav .logo .l-dark,
  #topnav .logo .logo-dark-mode {
    display: none;
  }
  
  #topnav .logo .l-light,
  #topnav .logo .logo-light-mode {
    display: inline-block;
  }
  
  #topnav .logo:focus {
    outline: none;
  }
  
  #topnav .has-submenu.active a {
    color: #ffffff;
  }
  
  #topnav .has-submenu.active .submenu li.active > a {
    color: #3192D4!important;
  }
  
  #topnav .has-submenu.active.active .menu-arrow {
    border-color: #2f55d4;
  }
  
  #topnav .has-submenu {
    position: relative;
  }
  
  #topnav .has-submenu .submenu {
    position: relative;
  }
  
  #topnav .has-submenu .submenu .submenu-arrow {
    border: solid #3c4858;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    position: absolute;
    right: 20px;
    top: 13px;
  }
  
  #topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
    border-color: #2f55d4;
  }
  
  #topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
    border-color: #2f55d4;
  }
  
  #topnav .navbar-toggle {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  
  #topnav .navbar-toggle .lines {
    width: 25px;
    display: block;
    position: relative;
    margin: 30px 0 26px 10px;
    height: 18px;
  }
  
  #topnav .navbar-toggle span {
    height: 2px;
    width: 100%;
    background-color: #3c4858;
    display: block;
    margin-bottom: 5px;
    transition: transform .5s ease;
  }
  
  #topnav .navbar-toggle span:last-child {
    margin-bottom: 0;
  }
  
  #topnav .buy-button {
    float: right;
    line-height: 74px;
  }
  
  #topnav .buy-button > li {
    line-height: initial;
  }
  
  #topnav .buy-button .login-btn-primary {
    display: none;
  }
  
  #topnav .buy-button .login-btn-light {
    display: inline-block;
  }
  
  #topnav .buy-button .dropdown .dropdown-toggle:after,
  #topnav .buy-menu-btn .dropdown .dropdown-toggle:after {
    display: none;
  }
  
  #topnav .navbar-toggle.open span {
    position: absolute;
  }
  
  #topnav .navbar-toggle.open span:first-child {
    top: 6px;
    transform: rotate(45deg);
  }
  
  #topnav .navbar-toggle.open span:nth-child(2) {
    visibility: hidden;
  }
  
  #topnav .navbar-toggle.open span:last-child {
    width: 100%;
    top: 6px;
    transform: rotate(-45deg);
  }
  
  #topnav .navbar-toggle.open span:hover {
    background-color: #2f55d4;
  }
  
  #topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
  #topnav .navbar-toggle .navigation-menu > li > a:hover, #topnav .navbar-toggle:focus {
    background-color: transparent;
  }
  
  #topnav .navigation-menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  #topnav .navigation-menu > li {
    float: left;
    display: block;
    position: relative;
    margin: 0 10px;
  }
  
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    color: #3192D4!important;
  }
  
  #topnav .navigation-menu > li > a {
    display: block;
    color: #3c4858;
    font-size: 13px;
    background-color: transparent !important;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple 	Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding-left: 15px;
    padding-right: 15px;
  }
  
  #topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
    color: #2f55d4;
  }
  
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding: 10px 20px;
    white-space: nowrap;
    font-size: 11.5px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: bolder;
    color: #3c4858 !important;
  }
  
  #topnav .navigation-menu .has-submenu .menu-arrow {
    border: solid #3c4858;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
    transition: all 0.5s;
    right: -1px;
    top: 30px;
  }
  
  #topnav .navigation-menu .has-submenu:hover .menu-arrow {
    transform: rotate(225deg);
  }
  
  #topnav .menu-extras {
    float: right;
  }
  
  #topnav.scroll {
    background-color: #ffffff;
    border: none;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  
  #topnav.scroll .navigation-menu > li > a {
    color: #3c4858;
  }
  
  #topnav.scroll .navigation-menu > li > .menu-arrow {
    border-color: #3c4858;
  }
  
  #topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
    color: #2f55d4;
  }
  
  #topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
    border-color: #2f55d4;
  }
  
  #topnav.defaultscroll.dark-menubar .logo {
    line-height: 70px;
  }
  
  #topnav.defaultscroll.scroll .logo {
    line-height: 62px;
  }
  
  #topnav.defaultscroll.scroll.dark-menubar .logo {
    line-height: 62px;
  }
  
  #topnav.nav-sticky {
    background: #ffffff;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li > a {
    color: #3c4858;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: #3192D4!important;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
    border-color: #3192D4!important;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: #3192D4!important;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: #3c4858;
  }
  
  #topnav.nav-sticky.tagline-height {
    top: 0 !important;
  }
  
  #topnav.nav-sticky .buy-button .login-btn-primary {
    display: inline-block;
  }
  
  #topnav.nav-sticky .buy-button .login-btn-light {
    display: none;
  }
  
  #topnav.nav-sticky .logo .l-dark {
    display: inline-block;
  }
  
  #topnav.nav-sticky .logo .l-light {
    display: none;
  }
  
  #topnav .logo {
    font-weight: normal;
    font-size: 20px;
    margin-right: 15px;
    padding: 0 0 6px;
    letter-spacing: 1px;
    line-height: 68px;
  }
  
  @media (min-width: 1025px) {
    #topnav .navigation-menu > li .submenu.megamenu {
      width: 1116px !important;
    }
  }
  
  @media screen and (max-width: 1024px) and (min-width: 992px) {
    #topnav .navigation-menu > li .submenu.megamenu {
      width: 936px !important;
    }
  }
  
  @media (min-width: 992px) {
    #topnav .navigation-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
      top: 33px !important;
    }
    #topnav .navigation-menu > .has-submenu.active .menu-arrow {
      top: 30px;
    }
    #topnav .navigation-menu > li .submenu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      padding: 15px 0;
      list-style: none;
      min-width: 180px;
      visibility: hidden;
      opacity: 0;
      margin-top: 10px;
      transition: all .2s ease;
      border-radius: 6px;
      background-color: #ffffff;
      box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    }
    #topnav .navigation-menu > li .submenu li {
      position: relative;
    }
    #topnav .navigation-menu > li .submenu li a {
      display: block;
      padding: 10px 20px;
      clear: both;
      white-space: nowrap;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #3c4858 !important;
      transition: all 0.3s;
    }
    #topnav .navigation-menu > li .submenu li a:hover {
      color: #3192D4!important;
    }
    #topnav .navigation-menu > li .submenu li ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
    #topnav .navigation-menu > li .submenu.megamenu {
      white-space: nowrap;
      left: 50%;
      transform: translateX(-50%);
      position: fixed;
      top: auto;
      display: flex;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li {
      overflow: hidden;
      vertical-align: top;
      width: 20%;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
      left: 100%;
      top: 0;
      margin-left: 10px;
      margin-top: -1px;
    }
    #topnav .navigation-menu > li .submenu > li .submenu {
      left: 101%;
      top: 0;
      margin-left: 10px;
      margin-top: -1px;
    }
    #topnav .navigation-menu > li > a {
      padding-top: 25px;
      padding-bottom: 25px;
      min-height: 62px;
    }
    #topnav .navigation-menu > li:hover > .menu-arrow {
      border-color: #2f55d4;
    }
    #topnav .navigation-menu > li:hover > a,
    #topnav .navigation-menu > li.active > a {
      color: #3192D4!important;
    }
    #topnav .navigation-menu > li.last-elements .submenu {
      left: auto;
      right: 0;
    }
    #topnav .navigation-menu > li.last-elements .submenu:before {
      left: auto;
      right: 10px;
    }
    #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
      left: auto;
      right: 100%;
      margin-left: 0;
      margin-right: 10px;
    }
    #topnav .navigation-menu.nav-light > li > a {
      color: rgba(255, 255, 255, 0.5);
    }
    #topnav .navigation-menu.nav-light > li.active > a {
      color: #ffffff !important;
    }
    #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
      border-color: #ffffff !important;
    }
    #topnav .navigation-menu.nav-light > li:hover > a {
      color: #ffffff !important;
    }
    #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
      border-color: rgba(255, 255, 255, 0.5);
    }
    #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
      border-color: #ffffff !important;
    }
    #topnav .navigation-menu.nav-right {
      justify-content: flex-end !important;
    }
    #topnav .navigation-menu.nav-left {
      justify-content: flex-start !important;
    }
    #topnav .navigation-menu.nav-left > li.last-elements .submenu {
      left: 0 !important;
      right: auto !important;
    }
    #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
      left: 45px !important;
      right: auto !important;
    }
    #topnav .buy-button {
      padding-left: 15px;
      margin-left: 15px;
    }
    #topnav .navbar-toggle {
      display: none;
    }
    #topnav #navigation {
      display: block !important;
    }
    #topnav.scroll {
      top: 0;
    }
    #topnav.scroll .navigation-menu > li > a {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    #topnav.scroll-active .navigation-menu > li > a {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  
  @media (max-width: 991px) {
    #topnav {
      background-color: #ffffff;
      box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
      min-height: 74px;
    }
    #topnav .logo .l-dark {
      display: inline-block !important;
    }
    #topnav .logo .l-light {
      display: none !important;
    }
    #topnav .container {
      width: auto;
    }
    #topnav #navigation {
      max-height: 400px;
    }
    #topnav .navigation-menu {
      float: none;
    }
    #topnav .navigation-menu > li {
      float: none;
    }
    #topnav .navigation-menu > li .submenu {
      display: none;
      list-style: none;
      padding-left: 20px;
      margin: 0;
    }
    #topnav .navigation-menu > li .submenu li a {
      display: block;
      position: relative;
      padding: 7px 15px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #3c4858 !important;
      transition: all 0.3s;
    }
    #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
      padding: 7px 15px;
    }
    #topnav .navigation-menu > li .submenu.open {
      display: block;
    }
    #topnav .navigation-menu > li .submenu .submenu {
      display: none;
      list-style: none;
    }
    #topnav .navigation-menu > li .submenu .submenu.open {
      display: block;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li > ul {
      list-style: none;
      padding-left: 0;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
      display: block;
      position: relative;
      padding: 10px 15px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      color: #8492a6;
    }
    #topnav .navigation-menu > li > a {
      color: #3c4858;
      padding: 10px 20px;
    }
    #topnav .navigation-menu > li > a:after {
      position: absolute;
      right: 15px;
    }
    #topnav .navigation-menu > li > a:hover,
    #topnav .navigation-menu > li .submenu li a:hover,
    #topnav .navigation-menu > li.has-submenu.open > a {
      color: #2f55d4;
    }
    #topnav .menu-extras .menu-item {
      border-color: #8492a6;
    }
    #topnav .navbar-header {
      float: left;
    }
    #topnav .buy-button .login-btn-primary {
      display: inline-block !important;
    }
    #topnav .buy-button .login-btn-light {
      display: none;
    }
    #topnav .has-submenu .submenu .submenu-arrow {
      transform: rotate(45deg);
      position: absolute;
      right: 20px;
      top: 12px;
    }
    #topnav .has-submenu.active a {
      color: #2f55d4;
    }
    #navigation {
      position: absolute;
      top: 74px;
      left: 0;
      width: 100%;
      display: none;
      height: auto;
      padding-bottom: 0;
      overflow: auto;
      border-top: 1px solid #f1f3f9;
      border-bottom: 1px solid #f1f3f9;
      background-color: #ffffff;
    }
    #navigation.open {
      display: block;
      overflow-y: auto;
    }
  }
  
  @media (max-width: 768px) {
    #topnav .navigation-menu .has-submenu .menu-arrow {
      right: 8px;
      top: 16px;
    }
  }
  
  @media (min-width: 768px) {
    #topnav .navigation-menu > li.has-submenu:hover > .submenu {
      visibility: visible;
      opacity: 1;
      margin-top: 0;
    }
    #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
      visibility: visible;
      opacity: 1;
      margin-left: 0;
      margin-right: 0;
    }
    #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
      visibility: visible;
      opacity: 1;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-toggle {
      display: block;
    }
  }
  
  @media (max-width: 425px) {
    #topnav .buy-menu-btn {
      display: block !important;
      margin: 0 10px;
      padding: 10px 20px;
    }
    #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
      transform: translate3d(0px, -54px, 0px) !important;
    }
  }
  
  .tagline {
    position: absolute;
    width: 100%;
    z-index: 99;
    font-size: 14px;
    padding: 13px 0;
  }
  
  @media screen and (max-width: 575px) {
    .tagline {
      display: none;
    }
  }
  
  @media screen and (max-width: 575px) {
    .tagline-height {
      top: 0px !important;
    }
  }
  
  @media (min-width: 576px) {
    .tagline-height {
      top: 47px !important;
    }
  }
  
  .sidebar-nav {
    padding: 15px 0;
  }
  
  .sidebar-nav > .navbar-item {
    padding: 3px 15px;
  }
  
  .sidebar-nav > .navbar-item .navbar-link {
    color: #3c4858 !important;
    font-size: 15px;
    font-weight: 600;
  }
  
  .sidebar-nav > .navbar-item .navbar-link .navbar-icon {
    font-size: 18px;
    margin-right: 6px;
  }
  
  #navmenu-nav li.active a {
    color: #2f55d4;
  }
  
  #navmenu-nav li.account-menu.active .navbar-link, #navmenu-nav li.account-menu:hover .navbar-link {
    color: #ffffff !important;
    background-color: #3192D4!important;
  }

  .footer .footer-lead {
    padding: 30px 0;
    border-top: 1px solid #F1F1F1;
  }

  /* ========== bg half padding =========== */
  .bg-half-260 {
    padding: 260px 0;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
  }
  .bg-half-120 {
    padding: 120px 0;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: top center;
  }
  
  .bg-half-170 {
    padding: 170px 0;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
  }
  
  .bg-half {
    padding: 200px 0 100px;
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
  }
  .btn-secondary2 {
    background-color: rgba(71,71,71,0.5) !important;
    border: 1px solid rgba(151,151,151,0.34) !important;
    color: #ffffff !important;
    box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.01);
  }
  
  .btn-secondary2:hover, .btn-secondary2:focus, .btn-secondary2:active, .btn-secondary2.active, .btn-secondary2.focus {
    background-color: rgba(71,71,71,0.8) !important;
    border-color: rgba(71,71,71,0.8) !important;
    color: #ffffff !important;
  }
  .form-control2 {
    border: 1px solid #dee2e6;
    font-size: 14px;
    line-height: 26px;
    background-color: #ffffff !important;
    border-radius: 6px;
}
.form-control2:focus {
    border-color: #3192D4;
    box-shadow: none;
  }
  
  .form-control2[readonly] {
    background-color: #ffffff;
  }
  
  .form-control2:disabled {
    background-color: #dee2e6;
  }


  /* ============ social leading icon ================ */
  .social-lead-icon li div {
    color: #ffffff;
    border: 1px solid #979797;
    background-color: #979797;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 28px;
    text-align: center;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
    margin-right: 5px;
  }
  
  .social-lead-icon li div .fea-social {
    stroke-width: 2;
  }
  
  .social-lead-icon li div:hover {
    background-color: #3192D4;
    border-color: #3192D4!important;
    color: #ffffff !important;
  }
  
  .social-lead-icon li div:hover .fea-social {
    fill: #3192D4;
  }
  
  .social-lead-icon.social li div {
    color: #adb5bd;
    border-color: #adb5bd;
  }
  
  .social-lead-icon.foot-social-lead-icon li div {
    color: #adb5bd;
    border-color: #283353;
  }



/* ========= custom css Start =============== */
.land_bg_detail .title{
    color: #ffffff;
    font-weight: normal;
    margin-bottom: 20px !important;
  }
  .land_bg_detail .tag_line{
    color: rgba(255, 255, 255, 0.80);
    font-weight: normal;
    margin-bottom: 20px !important;
  }
  /* change background btn hide shoe */
  .btn_hide{
    visibility: hidden;
    opacity: 0;
    transition: .5s;
  }
  .btn_show:hover .btn_hide{
    visibility: visible;
    opacity: 1;
  }
  .change_bg_dropdown{
    position: relative;
  }
  .change_bg_box{
    position: absolute;
    top: 40px;
    left: 0;
    max-width: 320px;
    width: 100%;
    height: auto;
    border: 1px solid rgba(151,151,151,0.34);
    border-radius: 6px;
    background-color: #313436;
    display: none;  
  }
  
  
  /* upload edit profile start */
  .file-input__input2 {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.60);
    font-size: 15px;
    padding: 6px 5px;
    background-color: transparent;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  
  .file-input__label2 svg {
    height: 18px;
    margin-right: 6px;
    color: rgba(255, 255, 255,0.74);
  }
  .edit_profile_remove a{
    font-size: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255,0.66);
    padding: 6px 5px;
  }
  /* upload edit profile end */
  
  /* register form css */
  .form-position{
    margin-top: -350px;
  }
  .reg_form{
    border: 1px solid #E6EAEE;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.12);
  }
  .form_time ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
  }
  .form_time ul li{
    display: inline-block;
    background-color: rgba(189, 206, 217, 0.48);
    min-width: 50px;
    padding: 6px;
    font-size: 12px;
  }
  .form_time ul li span{
    font-weight: bold;
  }
  
  @media screen and (max-width:1199px) {
    .form-position{
      margin-top: -200px;
    }
  }
  
  /* add form Modal css */
  #addform_landing .modal-content{
    background-color: #24282B;
    box-shadow: 0 0 1px 0 rgba(10,31,68,0.1), 0 26px 26px 0 rgba(10,31,68,0.12);
    color: rgba(255, 255, 255, 0.7);
  }
  .bg-req .form-check-input{
    background-color: rgba(218,216,222,0.58);
    border: 1px solid #898989;
  }
  .bg-req .form-check-input.form-check-input:checked {
    background-color: #3192D4;
    border-color: #3192D4;
  }
  .btn.btn-dark-footer {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    color: rgba(255, 255, 255, 0.61) !important;
    box-shadow: 0 3px 5px 0 transparent;
  }
  
  /* filed-edit reg btn css */
  .filed_edit_btn{
    position: relative;
    border: solid 1px transparent;
    padding: 10px 0;
    margin-right: -100px;
    padding-right: 100px;
    padding-left: 5px;
    margin-left: -5px;
    border-radius: 3px;
    transition: .5s;  
  }
  .filed_edit_btn_hover_off{
    position: relative;
    border: solid 1px transparent;
    padding: 10px 0;
    margin-right: -100px;
    padding-right: 100px;
    padding-left: 5px;
    margin-left: -5px;
    border-radius: 3px;
    transition: .5s;  
  }
  .filed_edit_icon{
    position: absolute;
    top: 17px;
    right: 5px;
    font-size: 20px;
    color: #808285;
    visibility: hidden;
    opacity: 0;
    transition: .2s;
  }
  .filed_edit_icon ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  .filed_edit_icon ul li{
    margin: 0 5px;
    cursor: pointer;
  }
  .filed_edit_btn:hover{
    background-color: #F9F9F9;
    border: solid 1px rgba(218,216,222,0.57);
  }
  .filed_edit_btn:hover .filed_edit_icon{
    visibility: visible;
    opacity: 1;
  }
  
  /* Edit reg btn css */
  .reg_btn{
    position: relative;
    border: solid 1px transparent;
    padding: 10px 5px;
    margin-right: -55px;
    padding-right: 55px;
    border-radius: 3px;
    transition: .5s;  
  }
  .reg_edit_icon{
    position: absolute;
    top: 13px;
    right: 14px;
    font-size: 20px;
    color: #808285;
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    transition: .2s;
  }
  .reg_btn:hover{
    background-color: #F9F9F9;
    border: solid 1px rgba(218,216,222,0.57);
  }
  .reg_btn:hover .reg_edit_icon{
    visibility: visible;
    opacity: 1;
  }

  .reg_btn_hover_off{
    position: relative;
    border: solid 1px transparent;
    padding: 10px 5px;
    margin-right: -55px;
    padding-right: 55px;
    border-radius: 3px;
    transition: .5s;  
  }
  
  
  /* Button edit form */
  #reg_popup .modal-content{
    background-color: #24282B;
    box-shadow: 0 0 1px 0 rgba(10,31,68,0.1), 0 26px 26px 0 rgba(10,31,68,0.12);
    color: rgba(255, 255, 255, 0.7);
  }
  .btn_txt label{
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    margin-bottom: 10px;
  }
  .btn_txt input{
    border: 1px solid #404040;
    border-radius: 4px;
    background-color: #373737;
  }
  .btn_txt input:focus {
    border-color: #404040;
    background-color: #373737;
    color: rgba(255, 255, 255, 0.6);
    box-shadow: none;
  }
  
  .btn_txt input[readonly] {
    background-color: #373737;
  }
  
  .btn_txt input:disabled {
    background-color: #373737;
  }
  .btn_txt input::placeholder{
    color: #ffffff;
  }
  .loader_center{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* btn edit form media query */
  @media screen and (max-width:1024px) {
    .filed_edit_btn {
      padding: inherit;
      margin-right: inherit;
      padding-right: inherit;
      padding-left: inherit;
      margin-left: inherit;
      border-radius: 5px;
      transition: .5s;
    }

    .filed_edit_btn_hover_off {
      padding: inherit;
      margin-right: inherit;
      padding-right: inherit;
      padding-left: inherit;
      margin-left: inherit;
      border-radius: 5px;
      transition: .5s;
    }

    .filed_edit_icon {
      top: 7px;
      right: 33px;
    }
  
    .reg_btn{
      margin-right: inherit;
      padding-right: inherit;
      padding: 5px 5px;
    }
    .reg_edit_icon{
      top: 8px;
      right: 20px;
      color: #ffffff;
    }
  }

  /* extra css custom for white form */

  .scroll-menu {
    overflow-x: auto;
    height: 200px;
  }

  .speaker-image{
    position: relative;
    cursor: pointer;
  }

  .close-icon{
    display: none;
    color: white;
    z-index: 9999;
    position: absolute;
    top: -5%;
    right: 0%;
    cursor: pointer;
    background-color: #181818;
    border-radius: 50%;
    width: 25px;
    text-align: center;
  }

  .speaker-image:hover + .close-icon{
    display: block;
  }

  .close-icon:hover{
    display: block;
  }

 .summaryTitle {
    white-space: nowrap; 
    width: 120px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .radialChart text{
    display: none;
  }

/* ======================================================================================
                            CUSTOM CSS BY BHAUTIK
========================================================================================= */
.deleteBtnStyle {
  color: #fff;
  border: 1px solid transparent !important;
}
.deleteBtnStyle:hover{
  box-shadow: 0 0 0 0;
  background-color: #24282B !important ;
  color : #fff;
}

.requiredFields{
  color: red;
}
.password {
	position: relative;
}

.password i {
  cursor: pointer;
	position: absolute;
	top: 44px;
	right: 12px;
}

.inputPassword{
  padding-right: 34px;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td{
  background:#181818;
}
.ant-empty-description{
  color: white;
}
.apexcharts-zoom-icon {
  display: none;
}
.apexcharts-pan-icon{
  display: none;
}
.apexcharts-reset-icon{
  display: none;
}
.apexcharts-menu{
  background: #181818 !important;
  border: 1px solid #181818 !important;
}
.apexcharts-theme-light .apexcharts-menu-item:hover{
  background: #3492d4 !important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover{
  background-color: #F0F8FF;
  color: red;
  font-weight: 700;
}

.cursorPointer{
  cursor: pointer;
}
.videoPlayer {
   width:100% !important;
   height:100vh !important;
}

.dashboard_active_users{
  max-height: calc(100vh - 600px) !important;
  /* overflow: auto */
  overflow: hidden !important;
}
.dashboard_active_users_toggle{
  overflow: auto !important;
}

.ant-tooltip-inner{
  background-color: #181818 !important;
}
.ant-empty{
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-40px);
}

.uploaded_images .preview_image{
  height: 60px !important;
}

.preview_img {
  height: 100% !important;
}

.close_ico {
  position: absolute;
  top: -10px;
  left: 4px;
  display: none;
}
.uploaded_images:hover .close_ico {
  display: block !important;
}

.gdrp_concent{
  margin-left: 15px;
}